<template>
  <v-app :style="colorVapp">
    <onebox_toolbar
      :aspectSearch="aspectData"
      :quicksearch="true"
      :parentfolder="parentfolder"
      :permissionfolder="permission_currentfolder"
      @advancesearch="fn_advancesearch"
      @fulltextsearch="fn_fulltextsearch"
      @inputdata="fn_filteredItems"
      @loadfile="fn_getfileandfoldercctv_child()"
      @callparentfolder="sendParentfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_toolbar>
    <v-content>
      <v-overlay :value="processloader" z-index="2" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay>
      <v-card class="elevation-0">
        <!-- Header Page -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-cctv</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage">&nbsp;{{ $t("toolbar.cctv") }}</span>
              </v-list-item-title>
            </v-list-item-content>
            <!-- button เปลี่ยนหน้า UI -->
            <v-list-item-action class="pa-0 text-center">
              <v-btn
                @click="fn_resetFile(), fn_changeLocalStorage()"
                outlined
                fab
                small
                :color="color.theme"
                v-if="resolutionScreen < 500 ? false : true"
              >
                <v-icon>{{ changeUI ? "view_list" : "view_module" }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-card class="elevation-0" :color="color.BG" id="thiscontainer_myfile">
          <v-card-text class="pa-0">
            <v-container @contextmenu="fn_show">
              <v-layout row wrap fill-height justify-center>
                <!-- chip countfile/folder -->
                <v-flex lg12 class="ma-4">
                  <!-- Multiple Download, Starred , Move, Delete  -->
                  <v-layout v-if="resolutionScreen >= 500" row wrap fill-height class="pl-2 pr-2">
                    <!-- Button Multiple -->
                    <v-flex xs4 lg4>
                      <div v-if="statusmutipledownload === false" class="pt-2">
                        <v-btn
                          :color="color.theme"
                          :dark="color.darkTheme"
                          class="ma-1"
                          fab
                          small
                          outlined
                          @click="statusmutipledownload = true"
                          v-if="changeUI===false"
                        >
                          <v-icon>apps</v-icon>
                        </v-btn>
                      </div>
                      <div v-else class="pt-2">
                        <v-btn small fab :color="color.theme" :dark="color.darkTheme" class="ma-1" @click="fn_multipledownload()">
                          <v-icon>mdi-download-multiple</v-icon>
                        </v-btn>
                        <v-btn
                          :dark="color.darkTheme"
                          :color="color.theme"
                          small
                          class="ma-1"
                          fab
                          @click="fn_setmultiupdate(filemultipledownload, 'S')"
                        >
                          <v-icon dark>star</v-icon>
                        </v-btn>
                        <v-btn fab small :color="color.theme" :dark="color.darkTheme" class="ma-1" @click="fn_multideletetotrash()">
                          <v-icon>delete</v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          small
                          class="ma-1"
                          :color="color.theme"
                          :dark="color.darkTheme"
                          @click="fn_appsbarmultimovefile($event)"
                        >
                          <v-icon>mdi-folder-swap</v-icon>
                        </v-btn>
                        <v-btn
                          color="error"
                          fab
                          small
                          class="ma-1"
                          @click="(statusmutipledownload = false), fn_clearmultipledownload()"
                        >
                          <v-icon>clear</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>

                    <!-- Countfiles&Folders -->
                    <v-flex xs12 lg12 class="text-right">
                      <!-- New Countfiles by scrolling -->
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{ totaldata["totalfolder"] }}
                        {{ totaldata["totalfolder"] > 1 ? $t("folders") : $t("folder") }}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{ totaldata["totalfile"] }}
                        {{ totaldata["totalfile"] > 1 ? $t("files") : $t("file") }}
                      </v-chip>
                    </v-flex>
                  </v-layout>

                  <!-- หน้าจอxs -->
                  <v-layout v-else row wrap>
                    <v-flex xs12 sm12 lg12 class="text-right">
                      <!-- New Countfiles by scrolling -->
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>folder</v-icon>
                        {{ totaldata["totalfolder"] }}
                      </v-chip>
                      <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                        <v-icon left>mdi-file</v-icon>
                        {{ totaldata["totalfile"] }}
                      </v-chip>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs12 lg12 class="pa-4">
                  <!-- หน้าจอธรรมดา -->
                  <div v-if="$vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'md'">
                    <!-- หน้าจอ grid -->
                    <div v-if="changeUI === true">
                      <!-- โชว์ไฟล์ที่เปิดล่าสุด
                        <v-card class="elevation-0" :color="color.BG" v-show="false">
                          <v-card-title>{{$t('recentfile')}}</v-card-title>
                          <v-layout wrap row align-center justify-center>
                            <v-flex lg11>
                              <v-row>
                                <v-col>
                                  <div style="width:100%;max-width:100%;overflow: hidden;">
                                    <slider ref="slider" :options="optionSlider">
                                      <slideritem
                                        v-for="(item,index) in filesLastView"
                                        :key="index"
                                        :style="styleSlider"
                                      >
                                        <div
                                          id="slideritem"
                                          class="d-flex pl-4 pr-4"
                                          style="width:100%;"
                                          @dblclick="gotodirectory(item.file_id,item.file_type,item.file_department,item)"
                                        >
                                          <v-icon
                                            class
                                            large
                                            :color="item.file_icon[1]"
                                          >{{ item.file_icon[0] }}</v-icon>
                                          <v-card-text
                                            style="color:#000000;"
                                            class="d-flex justify-start"
                                          >{{item.file_name}}</v-card-text>
                                        </div>
                                      </slideritem>
                                    </slider>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>
                        <br /> -->
                      
                      <drag-selector v-model="checkedList" @change="fn_handleDragSelectorChange(checkedList)">
                        <!-- folder -->
                        <v-card
                          class="elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="fn_resetFile"
                          v-if="totaldata['totalfolder'] > 0"
                        >
                          <v-card-title><b>{{ totaldata["total_folder"] == 0 ? "" : $t("folders") }}</b></v-card-title>
                          <v-layout justify-center wrap row align-center>
                            <v-flex lg11>
                              <v-row>
                                <v-col
                                  v-for="(item, index) in rootfile.filter((item) => {return (item.file_type === 'folder' || item.type === 'folder') && item.file_status !== 'M';})"
                                  :key="index"
                                  cols="6"
                                  md="3"
                                >
                                  <drag-selector-item class="drag-selector__item" :value="item">
                                    <v-card
                                      class="pa-2 d-flex align-center"
                                      @contextmenu="fn_rightclickfileandfolder($event, item)"
                                      @dblclick="fn_gotodirectory(item.file_id, item.file_type, item.file_department, item)"
                                      hover
                                      outlined
                                      :title="item.file_name"
                                      :color="fn_selectColor(item.file_name, 'card')"
                                      @click.ctrl="removeCheckedListItem(item)"
                                      @click.exact="shiftSelect(item)"
                                    >
                                      <!-- FILE ICON -->
                                      <div>
                                        <v-badge
                                          offset-x="10"
                                          offset-y="6"
                                          color="orange"
                                          :icon="fn_vBadgeIcon(item)"
                                          v-if="(item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P') || item.file_status_share === 'Y'"
                                        >
                                          <v-icon large :color="item.file_icon[1]">{{
                                            item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0]
                                          }}</v-icon>
                                        </v-badge>
                                        <v-icon v-else large :color="item.file_icon[1]">{{
                                          item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0]
                                        }}</v-icon>
                                      </div>
                                      <!-- FILE NAME -->

                                      <div
                                        style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px"
                                        :style="fn_selectColor(item.file_name, 'text')"
                                      >
                                        {{ item.file_name }}
                                      </div>
                                    </v-card>
                                  </drag-selector-item>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>
                        <!-- file -->
                        <v-card
                          class="elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="fn_resetFile"
                          v-if="totaldata['totalfile'] !== 0"
                        >
                          <v-card-title><b>{{ totaldata["totalfile"] == 0 ? "" : $t("files") }}</b></v-card-title>
                          <v-layout justify-center wrap row align-center>
                            <v-flex lg11>
                              <v-row>
                                <!-- || item.type !== 'folder' -->
                                <v-col
                                  class
                                  v-for="(item, index) in rootfile.filter((item) => {return item.file_type !== 'folder';})" :key="index" cols="6" md="3">
                                  <drag-selector-item class="drag-selector__item" :value="item">
                                    <v-card
                                      class="pa-2 d-flex align-center"
                                      @contextmenu="fn_rightclickfileandfolder($event, item)"
                                      @dblclick="fn_gotodirectory(item.file_id, item.file_type, item.file_department, item)"
                                      hover
                                      outlined
                                      :title="item.file_name"
                                      :color="fn_selectColor(item.file_name, 'card')"
                                      @click.ctrl="removeCheckedListItem(item)"
                                      @click.exact="shiftSelect(item)"
                                    >
                                      <v-badge
                                        offset-x="10"
                                        offset-y="6"
                                        color="orange"
                                        :icon="fn_vBadgeIcon(item)"
                                        v-if="(item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P') || item.file_status_share === 'Y'"
                                      >
                                        <div>
                                          <v-icon
                                            color="orange"
                                            style="position: absolute;z-index:1;left:-8px;top:-11px"
                                            v-if="item.file_status === 'S'"
                                            >star</v-icon
                                          >
                                          <v-icon large style="position: relative;" :color="item.file_icon[1]">{{item.file_icon[0]}}</v-icon>
                                        </div>
                                      </v-badge>
                                      <div v-else>
                                        <v-icon
                                          color="orange"
                                          style="position: absolute;z-index:1;left:1px;top:-3px"
                                          v-if="item.file_status === 'S'"
                                          >star</v-icon
                                        >
                                        <v-icon large style="position: relative;" :color="item.file_icon[1]">{{
                                          item.file_icon[0]
                                        }}</v-icon>
                                      </div>
                                      <div
                                        style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px"
                                        :style="fn_selectColor(item.file_name, 'text')"
                                      >
                                        {{ item.file_name }}
                                      </div>
                                    </v-card>
                                  </drag-selector-item>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-card>
                        <v-card
                          v-if="rootfile.length === 0 && disablescrolling === true && loaddataprogress === false"
                          class="pb-4 elevation-0"
                          :color="color.BG"
                          style="cursor: default;"
                          @mousedown="fn_resetFile"
                        >
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </drag-selector>
                    </div>
                    <!-- หน้าจอ ตาราง -->
                    <div v-else>
                      <v-data-table
                        v-model="filemultipledownload"
                        :headers="headers"
                        :items="rootfile"
                        item-key="file_id"
                        :single-select="true"
                        :search="search"
                        class="elevation-0 drag-selector pa-1"
                        :show-select="statusmutipledownload"
                        item-selected
                        :no-data-text="$t('tablefile.empty')"
                        :items-per-page="rootfile.length"
                        :hide-default-footer="true"
                      >
                        <!-- <template v-slot:top>
                                <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                            </template>-->
                        <template v-slot:[`header.file_type`]="{ header }">
                          <span :style="headerTable">{{ header.text }}</span>
                        </template>
                        <template v-slot:[`header.file_name`]="{ header }">
                          <span
                            class="pointer"
                            @click="(multiSort = !multiSort), (sortby = 'name'), (clickSort = true), sortFile(rootfile)"
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_owner`]="{ header }">
                          <span
                            class="pointer"
                            @click="(multiSort = !multiSort), (sortby = 'owner'), (clickSort = true), sortFile(rootfile)"
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_lastdtm`]="{ header }">
                          <span
                            class="pointer"
                            @click="(multiSort = !multiSort), (sortby = 'date'), (clickSort = true), sortFile(rootfile)"
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_size`]="{ header }">
                          <span
                            class="pointer"
                            @click="(multiSort = !multiSort), (sortby = 'size'), (clickSort = true), sortFile(rootfile)"
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_status`]="{ header }">
                          <span :style="headerTable">{{ $t(header.text) }}</span>
                        </template>
                        <!-- --------------------------------------------------------------------------------------------------------------------------------- -->
                        <template v-slot:item="props">
                          <tr
                            style="cursor: pointer"
                            @contextmenu="fn_rightclickfileandfolder($event, props.item)"
                            @dblclick="
                              fn_gotodirectory(props.item.file_id, props.item.file_type, props.item.file_department, props.item)
                            "
                          >
                            <td class="text-center" v-show="statusmutipledownload">
                              <v-checkbox v-model="filemultipledownload" :value="props.item" />
                            </td>
                            <td width="7%" class="text-center" style="padding:0px;margin:0px;">
                              <v-badge color="orange" overlap icon="link" v-if="props.item.file_status_sharelink === 'Y' || props.item.file_status_sharelink === 'P'">
                                <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              </v-badge>
                              <v-icon v-else large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                            </td>

                            <td width="33%" :title="props.item.file_name" style="padding:0px;margin:0px;">
                              <div style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis; width:300px">
                                {{ props.item.file_name }}
                              </div>
                            </td>

                            <td width="15%" style="padding:0px;margin:0px;" v-if="$t('default') === 'th'">
                              <div style="justify-content: center;">
                                {{ props.item.file_owner_th }}
                                <v-icon small color="orange" v-if="props.item.file_status_share === 'Y'">group_add</v-icon>
                              </div>
                            </td>
                            <td width="18%" style="padding:0px;margin:0px;" v-else>
                              <div style="justify-content: center;">
                                {{ props.item.file_owner_eng }}
                                <v-icon small color="orange" v-if="props.item.file_status_share === 'Y'">group_add</v-icon>
                              </div>
                            </td>
                            <td width="15%" style="padding:0px;margin:0px;">
                              {{ fn_formatdatetime(props.item.file_lastdtm) }}
                            </td>
                            <td width="15%" style="padding:0px;margin:0px;">
                              {{ fn_calculatesize(props.item.file_size) }}
                            </td>
                            <td
                              width="10%"
                              class="text-center"
                              v-if="props.item.file_status === 'M'"
                              style="padding:0px;margin:0px;"
                            ></td>

                            <td width="12%" v-else class="text-center" style="padding:0px;margin:0px;">
                               <v-btn
                               text
                                class="elevation-0"
                                fab
                                small
                                @click="setPermissionFileStar(props.item, 'N')"
                                v-if="props.item.file_status === 'S'"
                              >
                                <v-icon color="amber">star</v-icon>
                              </v-btn>
                              <v-btn class="elevation-0" fab small text @click="fn_setPermissionFileStar(props.item, 'S')" v-else>
                                <v-icon>star_border</v-icon>
                              </v-btn>
                              <v-btn class="elevation-0" fab small outlined @click="fn_rightclickfileandfolder($event, props.item)">
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                  <!-- หน้าจอมือถือ -->
                  <div v-else class="ma-2">
                    <v-list two-line class="mb-6 pb-6 elevation-0" v-if="rootfile.length > 0">
                      <v-list-item
                        v-for="(item, index) in rootfile"
                        :key="index"
                        @contextmenu="fn_rightclickfileandfolder($event, item)"
                        @dblclick="fn_gotodirectory(item.file_id, item.file_type, item.file_department, item), newpreviewfile(item)"
                        style="cursor: pointer;"
                      >
                        <v-list-item-action>
                          <v-badge color="orange" overlap icon="link" v-if="item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P'">
                            <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                          </v-badge>
                          <v-icon v-else large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title v-text="item.file_name"></v-list-item-title>
                          <v-list-item-subtitle v-text="fn_calculatesize(item.file_size)"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn class="elevation-0" fab small outlined @click.stop="rightclickfileandfolder($event, item)">
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-list
                      v-if="rootfile.length === 0 && disablescrolling === true && loaddataprogress === false"
                      class="elevation-0"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-flex>
              </v-layout>
              <scroll-loader class="pa-0" :loader-method="fn_getfileandfoldercctv" :loader-disable="disablescrolling">
              </scroll-loader>
              <div class="text-center" v-if="loaddataprogress === true">
                <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
              </div>
            </v-container>
            <previewfile
              :show="openpreviewfile"
              :filedata="currentfile"
              :parentfolder="parentfolder"
              @closepreviewfile="openpreviewfile = false"
              @openpreviewfile="openpreviewfile = true"
              @closeDrag="fn_removeEvent()"
              @openDrag="fn_addEvent()"
            ></previewfile>
              <mainrightclick
              :show="showMenu"
              :permissionfolder="permission_currentfolder"
              :parentfolder="parentfolder"
              :AreaX="x"
              :AreaY="y"
              @loaddatafrommainright="fn_loadstorage(), fn_getfileandfoldercctv_child(), (showMenu = false)"
              @closemainonly="showMenu = false"
              @closeDrag="fn_removeEvent()"
              @openDrag="fn_addEvent()"
            ></mainrightclick>
            <multifilerightclick
              :show="rightclickmultifile"
              :AreaX="x"
              :AreaY="y"
              :filemultipledownload="filemultipledownload"
              :parentfolder="parentfolder"
              @closecurrentonly="rightclickmultifile = false"
              @reload="fn_loadstorage(),fn_getfileandfoldercctv_child(), (filemultipledownload = []), (checkedList = [])"
              @clearparentvalue="clearparentvalue()"
              @closeappsbar="statusmutipledownload = false"
              @multideleteforbusinsess="fn_multideletetotrash()"
            ></multifilerightclick>
            <currentfilerightclick
              :parentfolder="parentfolder"
              :show="showcurrentMenu"
              :AreaX="x"
              :AreaY="y"
              @callfile="fn_gotodirectory"
              @loadfolder="getfileandfoldercctv_child(), (statusmutipledownload = false)"
              @callparantfolder="fn_gotoparent"
              @callupdatestatusfile="fn_setPermissionFileStar"
              @newmultidelete="fn_multideletetotrash"
              @closecurrent="fn_loadstorage(), fn_getfileandfoldercctv_child(), (showcurrentMenu = false)"
              @closecurrentonly="showcurrentMenu = false"
              :file="currentfile"
              :fileLength="filemultipledownload.length"
              :filemultipledownload="filemultipledownload"
              @closeDrag="fn_removeEvent()"
              @openDrag="fn_addEvent()"
              @reload="fn_getfileandfoldercctv_child()"
            ></currentfilerightclick>
            <dialogsnackbermultidowload
              :show="opensnackbarmultidownload"
              @closedialog="opensnackbarmultidownload = false"
              :percent="percent"
              :date="datetime"
            ></dialogsnackbermultidowload>
            <multi_movefile
              :show="opendialogmultimove"
              :filemultipledownload="filemultipledownload"
              :parentfolder="parentfolder"
              @closedialog="opendialogmultimove = false"
              @reload="fn_getfileandfoldercctv_child(), (filemultipledownload = []), (checkedList = [])"
              @closeappsbar="statusmutipledownload = false"
            ></multi_movefile>
          </v-card-text>
        </v-card>
      </v-card>
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { slider, slideritem } from "vue-concise-slider";
import onebox_toolbar from "../components/layout/layout-toolbar-new";
const previewfile = () => import("../components/optional/dialog-preview");
const mainrightclick = () => import("../components/contextmenu/mainrightclick");
const currentfilerightclick = () => import("../components/contextmenu/currentfilerightclick");
const multifilerightclick = () => import("../components/contextmenu/multifilerightclick");
const dialogsnackbermultidowload = () => import("../components/optional/dialog-snackbarmultidowmload");
const multi_movefile = () => import("../components/optional/dialog-multimovefile");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  components: {
    onebox_toolbar,
    previewfile,
    mainrightclick,
    currentfilerightclick,
    multifilerightclick,
    dialogsnackbermultidowload,
    multi_movefile
  },
  data: function() {
    return {
      opendialogmultimove:false,
      percent: 0,
      opensnackbarmultidownload:false,
      datetime: "",
      rightclickmultifile:false,
      showcurrentMenu:false,
      showMenu:false,
      removeevent: false,
      parentfolder: "",
      openpreviewfile:false,
      fileexpired: [],
      folderexpired: [],
      currentfile: "",
      disablescrolling: true,
      loaddataprogress: true,
      x: 0,
      y: 0,
      showexpiredtMenu: false,
      totaldata: { totalall: 0, totalfile: 0, totalfolder: 0 },
      checkedList: [],
      filemultipledownload: [],
      page: 1,
      size: 30,
      rootfile: [],
      buff: [],
      statusmutipledownload: false,
      search: "",
      changeUI: localStorage.getItem("changeStoreUI") === "true" ? true : false,
      aspectData: [{ aspect: "False", aspect_key: [] }],
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
      permission_currentfolder: {
        download: "False",
        edit: "False",
        view_only: "True",
        upload: "False",
        delete: "False",
      },
      x: 0,
      y: 0,
      headers: [
        {
          text: "#",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "33%",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          align: "left",
          value: "file_owner",
          width: "15%",
          sortable: false,
        },
        {
          text: "tablefile.lastmodified",
          value: "file_lastdtm",
          width: "18%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "15%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "12%",
          align: "right",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapState(["username", "authorize", "account_active", "service", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        // Old
        let l = this.rootfile.length;

        // New api
        // let l = this.fileCount;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    showdatafilter() {
      // console.log(search);
      return this.rootfile.filter((item) => {
        return item.file_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      // console.log(this.color.theme);

      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      //return "color:" + this.color.theme + ";";
      return "color:" + "white" + ";";
    },
  },
   watch: {
    // checkedList(val){
    //   console.log('watch checkedlist:',val)
    // },
    rightclickmultifile(val) {
      if (val === false) this.checkedList = [];
    },
    
    permission_currentfolder(val) {
      if (val["upload"] == "False") {
        this.fn_removeEvent();
      } else {
        this.fn_addEvent();
      }
    },
  },
  methods: {


    shiftSelect(item) {
      // คลิ๊ก item ที่ select ไว้ clear ค่าที่ไม่ได้ click ใน select ทั้งหมด
      if (this.statusmutipledownload == true) {
      } else {
        //แบบเดิมไม่มี if else
        this.fn_resetFile();
        this.filemultipledownload.push(item);
        this.checkedList.push(item);
        console.log("Clear", this.filemultipledownload);
      }
    },
    
    fn_appsbarmultimovefile(e) {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        e.preventDefault();
        this.x = e.clientX;
        this.y = e.clientY;
        this.fn_sendParentfolder();
        this.opendialogmultimove = true;
      }
    },

    // ติดดาว multi file/folder
    async fn_setmultiupdate(filemultipledownload, status) {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        let count_item_nostar = 0;
        let count_item_star = 0;
        let status_star;
        let apifile;
        let apifolder;
        let payloadfile;
        let payloadfolder;
        let msgres;
        let filedata = [];
        let folderdata = [];
        let auth = await gbfGenerate.generateToken();
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          if (filemultipledownload[i].file_status !== status) {
            count_item_nostar++;
            if (filemultipledownload[i].file_type === "folder") {
              folderdata.push(this.filemultipledownload[i]["file_id"]);
            } else {
              filedata.push(this.filemultipledownload[i]["file_id"]);
            }
          } else {
            count_item_star++;
            console.log("repeat", filemultipledownload[i]);
            if (filemultipledownload[i].file_type === "folder") {
              folderdata.push(this.filemultipledownload[i]["file_id"]);
            } else {
              filedata.push(this.filemultipledownload[i]["file_id"]);
            }
          }
        }
        if(count_item_star == filemultipledownload.length){
          status_star = 'N'
        }else if(count_item_nostar <= filemultipledownload.length){
          status_star = 'S'
        }
        payloadfolder = {
          list_folder: folderdata,
          account_id: this.dataAccountId,
          status_folder: status_star,
        };
        apifolder = "/api/multiple_update_status_folders";
        payloadfile = {
          list_file: filedata,
          account_id: this.dataAccountId,
          status_file: status_star,
        };
        apifile = "/api/multiple_update_status_files";

        console.log("folder", folderdata);
        console.log("file", filedata);
        try {
          let resultFile = await this.axios.post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifile, payloadfile,{headers: { Authorization: auth.code },});
          console.log("resultFile", resultFile);
          console.log("result =>", resultFile.data.result);
          let resultFolder = await this.axios.post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifolder, payloadfolder,{headers: { Authorization: auth.code },});
          console.log("resultFolder", resultFolder.status);
          console.log("result =>", resultFolder.data.result);
          if (resultFile.data.status == "OK" || resultFolder.data.status == "OK") {
            if (status_star === "S") {
              // "ติดดาวสำเร็จ";
              msgres = this.$t("toast.multiset.star");
              // } else if (status === "T") {
              //   // "นำออกสำเร็จ";
              //   msgres = this.$t("toast.multiset.remove");
            } else {
              // "ยกเลิกติดดาวสำเร็จ";
              msgres = this.$t("toast.multiset.cancle");
            }
            Toast.fire({
              icon: "success",
              title: msgres,
            });

            //OLD
            this.statusmutipledownload = false;
            this.fn_clearmultipledownload();
            // this.loadfolder();
            this.fn_getfileandfoldercctv_child();
            //NEW
            // this.statusmutipledownload = false;
            // this.clearmultipledownload();
            // this.loadfileandfolder();
          } else {
            console.log("ERROR");
            Toast.fire({
              icon: "error",
              title: resultFile.data.errorMessage || resultFolder.data.errorMessage,
            });
          }
        } catch (error) {
          console.log("result ERROR !!!", error);
          // แก้เลือกแต่ File await รอของ Folder ไม่โหลดค่าใหม่

          this.statusmutipledownload = false;
          this.fn_clearmultipledownload();
          // this.loadfolder();
          this.fn_getfileandfoldercctv_child();
          // ถ้าเอาออก มันจะไม่ update status ของตัว File
          if (status === "S") {
            // "ติดดาวสำเร็จ";
            msgres = this.$t("toast.multiset.star");
            // } else if (status === "T") {
            //   // "นำออกสำเร็จ";
            //   msgres = this.$t("toast.multiset.remove");
          } else {
            // "ยกเลิกติดดาวสำเร็จ";
            msgres = this.$t("toast.multiset.cancle");
          }
          Toast.fire({
            icon: "success",
            title: msgres,
          });
        }
      }
    },

    //เคลียร์ select multi
    fn_clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
      this.checkedList = [];
    },

    fn_zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    //ดาวน์โหลดหลายไฟล์ 
   async fn_multipledownload() {
      this.percentCompleted = 0;
      this.percent = 0;
      var d = new Date();
      this.datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.fn_zeroPadding(d.getHours(), 2) +
        "-" +
        this.fn_zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.fn_zeroPadding(d.getSeconds(), 2);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        // console.log("ไฟล์ที่เลือก",this.filemultipledownload);
        let filedata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          if (this.filemultipledownload[i].file_type === "folder") {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: this.filemultipledownload[i]["file_type"],
            });
          } else {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: "file",
            });
          }
        }
        console.log("fileeeeeedataa", filedata);

        let payload = {
          list: filedata,
          account_id: this.dataAccountId,
        };
        console.log("payyyyyloaaaad", payload);
        let auth = await gbfGenerate.generateToken();
        // this.statusmutipledownload = true;
        this.opensnackbarmultidownload = true;
        this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/download_multiples_all", payload, {
            onDownloadProgress: (progressEvent) => {
              // console.log(progressEvent);
              let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
              // console.log(progresspercent);
              if (progresspercent >= 95) {
                this.percentCompleted = 100;
              } else {
                this.percentCompleted = progresspercent;
              }
              // console.log("this.percentCompleted",this.percentCompleted);
              this.percent = this.percentCompleted;
            },
            withCredentials: false,
            responseType: "arraybuffer",
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            if (response.statusText === "OK") {
              this.percentCompleted = 100;
              console.log(response);
            }
            const blob = new Blob([response.data]);
            const content = response.headers["content-type"];
            saveAs(blob, this.datetime + ".zip");
            this.fn_clearmultipledownload();
            // this.statusmutipledownload = false;
            this.opensnackbarmultidownload = false
          })
          .catch((error) => {
            this.opensnackbarmultidownload = false;
            // this.statusmutipledownload = false;
            this.$store.dispatch("show_loading", false);
            Toast.fire({
              icon: "error",
              title: this.$t("myinboxPage.msg.apierror"),
            });
            console.log(error);
          });
      }
    },

    fn_advancesearch(param) {
      this.disablescrolling = true;
      this.rootfile = [];
      this.buff = [];
      let response = param;
      if (param.data.status == "OK") {
        for (let i = 0; i < response["data"]["data"].length; i++) {
          let datafolder = {};
          datafolder["account_reciever"] = "";
          datafolder["account_sender"] = this.dataAccountId;
          if ("file_id" in response.data.data[i]) {
            datafolder["file_id"] = response.data.data[i]["file_id"];
          } else {
            datafolder["file_id"] = response.data.data[i]["id"];
          }
          datafolder["file_name"] = response.data.data[i]["name"];
          datafolder["file_size"] = response.data.data[i]["size"];
          datafolder["file_status"] = response.data.data[i]["status"];
          datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
          datafolder["file_owner_th"] = response.data.data[i]["name_th"];
          datafolder["file_linkshare"] = "";
          datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
          datafolder["file_date"] = "";
          datafolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
          datafolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
          if ("permission_account" in response.data.data[i]) {
            datafolder["file_permission"] = response.data.data[i]["permission_account"];
          } else {
            datafolder["file_permission"] = {
              admin_data: "False",
              delete: "False",
              download: "False",
              edit: "False",
              upload: "False",
              view_only: "True",
            };
          }
          datafolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
          datafolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
          datafolder["file_status_share"] = response.data.data[i]["status_share"];
          datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
          datafolder["file_type"] = response.data.data[i]["data_type"];
          datafolder["status_updating"] = response.data.data[i]["status_updating"];
          datafolder["file_department"] = "";
          datafolder["file_icon"] = this.seticon_v2(response.data.data[i]["data_type"],response.data.data[i]["filetype"]);
          datafolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
          datafolder["status_updating"] = response.data.data[i]["status_updating"];
          // console.log("kkkkkkkkkkk", this.rootfile);

          this.rootfile.push(datafolder);
          this.buff.push(datafolder);
        }
      }
    },

   async fn_fulltextsearch(text) {
      this.disablescrolling = true;
      this.rootfile = [];
      this.buff = [];
      let payload = {
        text: text["keyword"],
        account_id: this.dataAccountId,
        type_search: text["type"],
      };
      let auth = await gbfGenerate.generateToken();
     await this.axios.post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/full_text_search", payload,{headers: { Authorization: auth.code },}).then((response) => {
        if (response.data.status == "OK") {
          for (let i = 0; i < response["data"]["data"].length; i++) {
            let datafolder = {};
            datafolder["account_reciever"] = "";
            datafolder["account_sender"] = this.dataAccountId;
            datafolder["file_id"] = response.data.data[i]["file_id"];
            datafolder["file_name"] = response.data.data[i]["filename"];
            datafolder["file_size"] = response.data.data[i]["size_file"];
            datafolder["file_status"] = response.data.data[i]["status_file"];
            datafolder["file_owner_eng"] = "";
            datafolder["file_owner_th"] = "";
            datafolder["file_linkshare"] = "";
            datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
            datafolder["file_date"] = "";
            datafolder["file_permission"] = "";
            datafolder["file_status_share"] = "";
            datafolder["file_status_sharelink"] = "";
            datafolder["file_type"] = response.data.data[i]["data_type"];
            datafolder["file_department"] = "";
            datafolder["file_icon"] = this.seticon(response.data.data[i]["data_type"]);
            this.rootfile.push(datafolder);
            this.buff.push(datafolder);
          }
        } else {
          console.log(response.data);
        }
      });
    },

    fn_filteredItems(search) {
      this.disablescrolling = true;
      if (search !== "") {
        this.rootfile = this.buff;
        let filldata = this.rootfile.filter((item) => {
          return item.file_name.toLowerCase().indexOf(search.toLowerCase()) > -1 && item.file_type === "folder";
        });
        this.rootfile = [];
        filldata.forEach((filedata) => {
          this.rootfile.push(filedata);
        });
      } else {
        this.rootfile = this.buff;
      }
    },

     //ฟังก์ชั่นกลับไปแหล่งที่มา
    fn_gotoparent(parameter) {
      console.log("parameterparent", parameter);
      //parent folder
      this.parentidfile = parameter.file_parent_folder_id;
      console.log(this.parentidfile);
      
      if(this.parentidfile === this.dataAccountActive["directory_id"]){
        this.rootfile = [];
        this.buff = [];
        this.$router.replace("/mydrive");
        this.fn_getfileandfoldercctv_child();
      }else{
           if (this.parentidfile === "" || this.parentidfile === null || this.parentidfile === undefined) {
          // this.noparent = this.$route.params.id;
          this.noparent_M = this.dataAccountActive.business_info.my_folder_id;
          this.loadingdata = true;
          this.rootfile = [];
          this.buff = [];
          this.$router.replace({ path: "/directory/" + this.dataAccountActive["directory_id"] });
          this.fn_getfileandfoldercctv_child();
        } else {
          this.rootfile = [];
          this.buff = [];
          this.$router.replace({ path: "/directory/" + this.parentidfile });
          // this.parentfolder = this.$route.params.id;
          this.fn_getfileandfoldercctv_child();
        }
      }

    },
   
   // ตั้งไฟล์ติดดาว
   async fn_setPermissionFileStar(parameter, status) {
      let api;
      let payload;
      let msgres;
      console.log("status", status);
      if (status === "T") {
        this.fn_deletenewfunction(parameter);
      } else {
        if (parameter.file_type === "folder") {
          payload = {
            folder_id: parameter.file_id,
            folder_name: "",
            status_folder: status,
            move_to_id: "",
            account_id: parameter.account_sender,
            user_id: this.dataUsername,
          };
          api = "/api/v2/update_folder_id";
        } else if (parameter.file_type !== "folder") {
          payload = {
            file_id: parameter.file_id,
            filename: "",
            folder_id: "",
            status_file: status,
            account_id: parameter.account_sender,
            user_id: this.dataUsername,
          };
          api = "/api/v2/update_file_id";
        }
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload,{headers: { Authorization: auth.code },})
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              if (status === "S") {
                // "ติดดาว " + parameter.file_name + " สำเร็จ"
                msgres = this.$t("toast.text.starred") + parameter.file_name + this.$t("toast.text.success");
              }
              // else if (status === "T") {
              //   // "นำ " + parameter.file_name + " ออกสำเร็จ"
              //   msgres =
              //     this.$t("toast.text.remove") +
              //     parameter.file_name +
              //     this.$t("toast.text.success");
              // }
              else {
                // "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ"
                msgres = this.$t("toast.text.canclestart") + parameter.file_name + this.$t("toast.text.success");
              }

              Toast.fire({
                icon: "success",
                title: msgres,
              });
              // this.$emit('closecurrent')
              // this.loadfolder();
              this.fn_getfileandfoldercctv_child();
              // this.loadfileandfolder();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              // "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
              title: this.$t("toast.text.cannotstar") + parameter.file_name + this.$t("toast.text.textth"),
            });
          });
      }
    },
   
   //multi ลบ
    fn_multideletetotrash() {
      this.$store.dispatch("set_loading", true).then((res) => {});
      console.log("filemultipledownload", this.filemultipledownload);
      let datalist = [];
      for (let i = 0; i < this.filemultipledownload.length; i++) {
        console.log(this.filemultipledownload[i]);
        if (this.filemultipledownload[i].file_type === "folder") {
          console.log("folder");
          datalist.push({
            data_id: this.filemultipledownload[i]["file_id"],
            data_type: this.filemultipledownload[i]["file_type"],
            data_status: "T",
          });
        } else {
          console.log("file");
          datalist.push({
            data_id: this.filemultipledownload[i]["file_id"],
            data_type: "file",
            data_status: "T",
          });
        }
      }
      let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        data_list: datalist,
        // status_data:"T"
      };
      console.log("payload", payload);
      this.axios
        .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_multiples_file_folder_to_trash", payload)
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.$store.dispatch("set_loading", false).then((res) => {});
            Toast.fire({
              icon: "success",
              title: this.$t("toast.multiset.remove"),
            });
            this.statusmutipledownload = false;
            this.fn_clearmultipledownload();
            // this.loadfolder();
            this.fn_getfileandfoldercctv_child();
            this.fn_loadstorage();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
   
    fn_show(e) {
      e.preventDefault();
      this.fn_sendParentfolder();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        if (this.showcurrentMenu === true || this.rightclickmultifile === true) {
          this.showMenu = false;
        } else {
          setTimeout(() => {
            this.showMenu = true;
          }, 300);
        }
      });
    },

   //checkคลิกขวา
    fn_rightclickfileandfolder(e, filename) {
      if (this.filemultipledownload.length > 1) {
        this.rightclickmultifile = true;
      }
      e.preventDefault();
      this.fn_sendParentfolder();
      this.showcurrentMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.$nextTick(() => {
        this.currentfile = filename;
        this.showcurrentMenu = true;
        if (this.showMenu === true) {
          this.showcurrentMenu = false;
        } else if (this.rightclickmultifile === true) {
          this.showcurrentMenu = false;
        } else {
          setTimeout(() => {
            this.showcurrentMenu = true;
          }, 300);
        }
      });
    },
    
    fn_removeEvent() {
      console.log("close");
      // var self = this;
      // self.closeDragDrop = true;
      // window.removeEventListener("dragenter", self.dragenter, true);
      // window.removeEventListener("dragleave", self.dragleave, true);
      // window.removeEventListener("dragover", self.dragover, true);
      // window.removeEventListener("drop", self.drop, true);
      this.removeevent = true;
      console.log(this.removeevent);
    },
    
    fn_addEvent() {
      if (this.resolutionScreen >= 500) {
        console.log("open");
        // if (this.resolutionScreen >= 500) {
        //   var self = this;
        //   self.closeDragDrop = false;
        //   window.addEventListener("dragenter", self.dragenter, true);
        //   window.addEventListener("dragleave", self.dragleave, true);
        //   window.addEventListener("dragover", self.dragover, true);
        //   window.addEventListener("drop", self.drop, true);
        // }
        this.removeevent = false;
        console.log(this.removeevent);
      }
    },
    
    //โหลดจำนวน Storage ที่ใช้ไปทั้งหมด
    fn_loadstorage() {
      this.$store.dispatch("check_storage", this.dataAccountId).then((res) => {});
      this.$store.dispatch("check_color", localStorage.getItem("color")).then((res) => {});
    },
    
    fn_sendParentfolder() {
      // if(this.$route.params.id === undefined){
      //   this.parentfolder = ''
      // }else{
      //   this.parentfolder = this.$route.params.id
      // }

      this.parentfolder = this.account_active["directory_id"];
      // console.log(this.parentfolder);
    },
    
    //ไปที่หน้า directory 
    fn_gotodirectory(folderid, type, departmentid, file) {
      this.fn_resetFile()
      if (file.file_type === "folder") {
        this.$router.replace({ path: "/directory/" + folderid });
      } else {
        this.currentfile = file;
        this.openpreviewfile = true;
      }
    },

    //ลบไฟล์และโฟล์เดอร์
   async fn_deletenewfunction(parameter) {
      let payload = {
        account_id: parameter.account_sender,
        user_id: this.dataUsername,
        data_id: parameter.file_id,
        data_type: parameter.file_type === "folder" ? "folder" : "file",
        status_data: "T",
      };
      let auth = await gbfGenerate.generateToken();
      console.log("payload", payload);
      this.axios.post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_file_folder_to_trash", payload,{headers: { Authorization: auth.code },}).then((response) => {
        if (response.data.status === "OK") {
          console.log("delresponse", response);
          Toast.fire({
            icon: "success",
            title: this.$t("toast.text.remove") + parameter.file_name + this.$t("toast.text.success"),
          });

          this.fn_getfileandfoldercctv_child();
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage,
          });
        }
      });
    },

    //show icon ตามเงื่อนไข
    fn_vBadgeIcon(item) {
      let stringClass = "";
      if (item.file_status_sharelink === "Y") {
        stringClass = "link";
      } else {
        stringClass = "";
      }
      if (item.file_status_share === "Y") {
        if (stringClass == "") {
          stringClass = "group_add";
        } else {
          stringClass += " group_add";
        }
      }
      if (item.status_updating === "") {
        if (stringClass == "") {
          stringClass = "check";
        } else {
          stringClass += " check";
        }
      } else {
        stringClass += "";
      }
      return stringClass;
    },

    fn_selectColor(e, type) {
      if (type === "card") {
        if (this.filemultipledownload.length < 1) {
        } else {
          for (let i = 0; i < this.filemultipledownload.length; i++) {
            if (e === this.filemultipledownload[i].file_name) {
              return "#D6EAF8";
            }
          }
        }
      } else {
        if (this.filemultipledownload.length < 1) {
        } else {
          for (let i = 0; i < this.filemultipledownload.length; i++) {
            if (e === this.filemultipledownload[i].file_name) {
              return "color:#2471A3 ";
            }
          }
        }
      }
    },

    fn_handleDragSelectorChange(_checkedList) {
      console.log("Input:", _checkedList);
      // เอาตัวซ้ำออก _checkedList เป็น parameter แค่รับมา filter ค่าซ้ำออก
      let newArrayFile = [...new Set(_checkedList)];
      // fileter เอาค่าที่ไม่ต้องการออก
      this.filemultipledownload = newArrayFile.filter((element)=>{return element.status_lock_folder!=="Y" && element.status_lock_file !=="Y" && element.file_permission.edit === "True"});
      console.log("Output:", this.filemultipledownload);
      // Toast ยิงไปบอกว่าบางไฟล์ที่คลุมไม่ขึ้น user นั้นไม่มีสิทธ์ หรือ ไฟล์มันล็อกอยู่
      document.onmouseup=()=>{
        // Object.keys() ไว้เช็ค obj = {}
        if(Object.keys(this.filemultipledownload).length === 0 && this.checkedList.length > 1){
          console.log('CASE 1')
          // กรณี มีไฟล์แต่ติด permission ทั้งหมด
          // filemultipledownload = {} | newArrayFile > 1 (มากกว่า 0 ไม่ได้เพราะมีกรณีคลิ๊กไฟล์เดียว)
          Toast.fire({
            icon: "warning",
            title: this.$t('toast.nopermissionfile'),
            // Some files you do not have permission to access
          });
        } else if (Object.keys(this.filemultipledownload).length >= 1 && (Object.keys(this.filemultipledownload).length != newArrayFile.length) && newArrayFile.length != 0){
          // กรณีมีบางไฟล์ติด permission
          // filemultipledownload > 1 แต่มีบางตัว filter ออก | newArrayFile > 1 
          // ดัก newArrayFile.length != 0 สำหรับเวลา dblclick เข้าโฟลเดอร์
          console.log('checklist',this.checkedList)
          console.log('_checklist',_checkedList)
          console.log('newarray',newArrayFile)
          console.log('CASE 2')
          Toast.fire({
            icon: "warning",
            title: this.$t('toast.nopermissionfile'),
            // Some files you do not have permission to access
          });
        } else {
          console.log('else')
          // filemultipledownload 0 checkList 0
        }
        // console.log('filemultipledownload length:',Object.keys(this.filemultipledownload).length)
        // console.log('newArrayFile length:',Object.keys(_checkedList).length)
        // console.log('checkedList length:',this.checkedList.length)
      }
    },
   
   // ตั้ง icon file/folder
    fn_seticonNew(type, raw_parameter) {
      // console.log(parameter);

      let dataicon;
      let parameter;
      if (typeof raw_parameter === "string") {
        parameter = raw_parameter.toLowerCase();
      } else {
        parameter = raw_parameter;
      }
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      if (type == "folder") {
        dataicon = ["folder", "#FDD361"];
      } else {
        if (parameter === "xlsx" || parameter === "xls") {
          dataicon = ["mdi-file-excel-outline", "green"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint-outline", "orange"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word-outline", "primary"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box-outline", "blue-grey"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf-outline", "red"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code-outline", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text-outline", "blue-grey"];
        } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
          dataicon = ["mdi-file-image-outline", "blue-grey"];
        } else {
          // dataicon = ["mdi-file-question-outline", "black"];
          dataicon = ["mdi-file-outline", "black"];
        }
      }

      return dataicon;
    },

    //ขนาดไฟล์
    fn_calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },

    //formatdatetime ไว้ส่งค่าไปที่ api
    fn_formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
      }
    },

    fn_resetFile() {
      if (this.statusmutipledownload == true) {
      } else {
        //แบบเดิมไม่มี if else
        this.filemultipledownload = [];
        this.checkedList = [];
      }
    },

    //check เพื่อเปลี่ยนหน้า UI
    fn_changeLocalStorage() {
      if (localStorage.getItem("changeStoreUI") === "true") {
        localStorage.setItem("changeStoreUI", "false");
        this.changeUI = false;
      } else if (localStorage.getItem("changeStoreUI") === "false") {
        localStorage.setItem("changeStoreUI", "true");
        this.changeUI = true;
      } else {
        console.log("localStore", localStorage.getItem("changeStoreUI"));
        localStorage.removeItem("changeStoreUI");
      }
    },

    // ดึงข้อมูลโฟลเดอร์และไฟล์ แบบ Scroll โดยจะดึงแบบ limit offset /status_expired = 0
    async fn_getfileandfoldercctv() {
      let payload;
      this.disablescrolling = true;
      this.loaddataprogress = true;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      // console.log(cal_offset)
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }
      payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.account_active["directory_id"],
        status_file: "",
        status_folder: "",
        limit: cal_limit,
        offset: cal_offset,
        cctv_system: "Y",
      };

      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/select_files_and_folder_for_cctv", payload,{headers: { Authorization: auth.code },}
)
        .then((response) => {
          this.loaddataprogress = false;
          if (response.data.status === "OK") {
            console.log(response);
            console.log("reponseok", response.data.data);
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              if (response.data.data[i].data_type === "folder") {
                this.folderexpired.push(dataFileAndFolder);
              } else {
                this.fileexpired.push(dataFileAndFolder);
              }
              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] = response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] = response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] = response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] = response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] = response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] = response.data.data[i].permission_account;
              dataFileAndFolder["permission_department_setting"] = response.data.data[i].permission_setting;
              dataFileAndFolder["permission_role_setting"] = response.data.data[i].permission_role_setting;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] = response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] = response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].data_type;
              dataFileAndFolder["file_icon"] = this.fn_seticonNew(type, typefile[typefile.length - 1]);
              dataFileAndFolder["file_type"] = type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
              dataFileAndFolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
              dataFileAndFolder["status_expired"] = response.data.data[i]["status_expired"];
              dataFileAndFolder["expiration_time"] = response.data.data[i]["expiration_time"];
              dataFileAndFolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];
              this.rootfile.push(dataFileAndFolder);
           }

            console.log("roottttt", this.rootfile);
            console.log("cfolder", this.folderexpired);
            console.log("cfile", this.fileexpired);
            this.totaldata = {
              totalall: response.data.count,
              totalfile: response.data.count_file,
              totalfolder: response.data.count_folder,
            };
            this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            this.aspectData = [
              {
                aspect: response.data.your_folder[0]["aspect"],
                aspect_key: response.data.your_folder[0]["aspect_key"],
              },
            ];
            this.disablescrolling = this.totaldata["totalall"] <= this.rootfile.length;

            // console.log("Newrootfile", this.newrootfile);
          } else {
            this.disablescrolling = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.disablescrolling = true;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },

    // ดึงข้อมูลโฟลเดอร์และไฟล์ ในกรณีที่ต้องการดึงใหม่ตั้งแต่ต้น ใช้สำหรับให้ child component เรียกข้อมูล
   async fn_getfileandfoldercctv_child() {
      // this.rootfile = []
      let payload;
      this.disablescrolling = true;
      this.loaddataprogress = true;
      this.page = 1;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);

      // console.log(cal_offset)
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }
      payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.account_active["directory_id"],
        status_file: "",
        status_folder: "",
        limit: cal_limit,
        offset: cal_offset,
        cctv_system: "Y",
      };
      console.log(payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/select_files_and_folder_for_cctv", payload,{headers: { Authorization: auth.code },}
)
        .then((response) => {
          this.loaddataprogress = false;
          if (response.data.status === "OK") {
            console.log("response", response);
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              if (response.data.data[i].data_type === "folder") {
                this.folderexpired.push(dataFileAndFolder);
              } else {
                this.fileexpired.push(dataFileAndFolder);
              }
              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] = response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] = response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] = response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] = response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] = response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] = response.data.data[i].permission_account;
              dataFileAndFolder["permission_department_setting"] = response.data.data[i].permission_setting;
              dataFileAndFolder["permission_role_setting"] = response.data.data[i].permission_role_setting;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] = response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] = response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].data_type;
              dataFileAndFolder["file_icon"] = this.fn_seticonNew(type, typefile[typefile.length - 1]);
              dataFileAndFolder["file_type"] = type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
              dataFileAndFolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
              dataFileAndFolder["status_expired"] = response.data.data[i]["status_expired"];
              dataFileAndFolder["expiration_time"] = response.data.data[i]["expiration_time"];
              dataFileAndFolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];
              this.rootfile.push(dataFileAndFolder);
            }
            console.log("roottttt", this.rootfile);
            this.totaldata = {
              totalall: response.data.count,
              totalfile: response.data.count_file,
              totalfolder: response.data.count_folder,
            };
            this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            this.aspectData = [
              {
                aspect: response.data.your_folder[0]["aspect"],
                aspect_key: response.data.your_folder[0]["aspect_key"],
              },
            ];
            this.disablescrolling = this.totaldata["totalall"] <= this.rootfile.length;

            // console.log("Newrootfile", this.newrootfile);
          } else {
            this.disablescrolling = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.disablescrolling = true;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
  
  },
  mounted() {
    this.loaddataprogress = false;
    // this.getfileandfolder();
    this.disablescrolling = false;
  },
};
</script>
<style>
.drag-selector {
  padding: 15px;
}
.drag-selector__item {
  align-items: center;
}
template td {
  padding: 0px;
  margin: 0px;
}
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#demo {
  width: 100%;
  height: 100%;
}

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Sarabun, system-ui, Tahoma, sans-serif;
}

#dropzone {
  min-height: 100%;
}

#thiscontainer_myfile {
  height: calc(101vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}

.v-progress-circular {
  margin: 1rem;
}
#slideritem:hover {
  background-color: #d6eaf8;
  border-radius: 5px;
}
</style>
